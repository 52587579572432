function getBaseURL () {
    var URL = ''
    if (['localhost', '127.0.0.1'].includes(window.location.hostname)) {
      URL = 'https://localhost:8443'
    } else {
      URL = 'https://' + window.location.hostname
    }
    return URL
  }

function getApiKey () {
    var api_key = ''
    if (['localhost', '127.0.0.1'].includes(window.location.hostname)) {
      api_key = process.env.REACT_APP_DEVEL_API_KEY
    } else {
      api_key = process.env.REACT_APP_PROD_API_KEY
    }

    return api_key
  }

function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

export var baseURL = getBaseURL();
export var apiKey = getApiKey();
export var csrfToken = getCookie('XSRF-TOKEN');
