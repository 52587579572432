
import axios from 'axios';
import * as vars from './vars';

export function getReferralProgram (userId, program) {
    return axios({
        method: 'GET',
        url: vars.baseURL + '/api/v2/misc/referrals/programs/' + program,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data : { userId: userId }
    })
}

export function getLeaderboardEntries (program) {
  return axios({
      method: 'GET',
      url: vars.baseURL + '/api/v2/misc/' + program + '/referrals/leaderboard',
      headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
      data : { }
  })
}

export function getLeaderboardRanking (userId, program) {
    return axios({
        method: 'GET',
        url: vars.baseURL + '/api/v2/misc/' + program + '/referrals/leaderboard/rankings?userId=' + userId,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data : { }
    })
}

export function getReferralStatistics (userId, program) {
  return axios({
      method: 'GET',
      url: vars.baseURL + '/api/v2/users/' + userId + '/referrals/statistics?program=' + program,
      headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
      data : { }
  })
}

export function sendStudentReferral (email, userId, program) {
      return axios({
          method: 'POST',
          url: vars.baseURL + '/api/v2/misc/' + program + '/referrals',
          headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
          data : { email: email, referrerId: userId }
      })
  }

export function downloadEthically (referrer, ipAddress) {
    return axios({
        method: 'POST',
        url: vars.baseURL + '/api/v2/misc/downloads/ethically',
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data : { referrer: referrer, ipAddress: ipAddress }
    })
}

export function logPageLanding (page, referrer) {
    return axios({
        method: 'PUT',
        url: vars.baseURL + '/api/v2/misc/landings/' + page,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data : { referrer: referrer }
    })
}
