import axios from 'axios'
import * as vars from './vars';
import {getTimezone} from '../../Utils/time'

 export function getAllUsers () {
        return axios({
            method: 'GET',
            url: vars.baseURL + '/api/v2/users/all',
            headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
            data: {}
        })
    }

export function registerBasicUser (user) {
    return axios({
      method: 'POST',
      url: vars.baseURL + '/api/v2/users/registration/basic',
      headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
      data: user
    })
}

export function createUserProfile (profile) {
    return axios({
      method: 'PATCH',
      url: vars.baseURL + '/api/v2/users/registration/profile',
      headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
      data: profile
    })
  }

export function  updateUserProfile (userId,data) {
    data.userId = userId
    return axios({
      method: 'PATCH',
      url: vars.baseURL + '/api/v2/users/registration/profile',
      headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
      data: data
    })
  }

export function  getUserProfile (id) {
    const params = new URLSearchParams({userId:id})
    return axios({
      method: 'GET',
      url: vars.baseURL + '/api/v2/users/retrieval/profile?' + params,
      headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
      data: {}
    })
  }

export function  getUserProfileByUserName (userName) {
    const params = new URLSearchParams({userName:userName})
    return axios({
      method: 'GET',
      url: vars.baseURL + '/api/v2/users/retrieval/profile?' + params,
      headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
      data: {}
    })
  }

export function usernameAvailable (username) {
    const params = new URLSearchParams({userName:username})
    return axios({
      method: 'GET',
      url: vars.baseURL + '/api/v2/users/username/available?' + params,
      headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
      data: {}
    })
  }



  export function  getUserBasic (id) {
    const params = new URLSearchParams({userId:id})
    return axios({
      method: 'GET',
      url: vars.baseURL + '/api/v2/users/retrieval/basic?' + params,
      headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
      data: {}
    })
  }

  export function deleteUser (id, password) {
    const params = new URLSearchParams({userId:id})
    return axios({
      method: 'DELETE',
      url: vars.baseURL + '/api/v2/users/delete?' + params,
      headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
      data : {
        password: password
      }
  })
  }

  export function getUserRating (userId) {
    const params = new URLSearchParams({userId: userId})
    return axios({
        method: 'GET',
        url: vars.baseURL + '/api/v2/transaction/payee/verification_status?' + params,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: {}
    })
  }


  export function updateUser (userId, data) {
    const params = new URLSearchParams({userId:userId})
    return axios({
        method: 'PATCH',
        url: vars.baseURL + '/api/v2/users/update?'+ params ,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: data
    })
  }


  export function updateUsername (userId, userName){
    const params = new URLSearchParams({userId:userId})
    return axios({
        method: 'PATCH',
        url: vars.baseURL + '/api/v2/users/update/username?'+ params ,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: {
          userName: userName
        }
      })
    }

  export function deleteResume (id) {
    const params = new URLSearchParams({userId:id})
    return axios({
      method: 'DELETE',
      url: vars.baseURL + '/api/v2/users/user/resume?' + params,
      headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
      data : {}
    })
  }

  export function updateEmailStart (userId, email){
    const params = new URLSearchParams({userId:userId})
    return axios({
        method: 'PATCH',
        url: vars.baseURL + '/api/v2/users/update/email/start?'+ params ,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: {
          email: email
        }
    })
  }

  export function updateEmailFinish (userId, email, code){
    const params = new URLSearchParams({userId:userId})
    return axios({
        method: 'PATCH',
        url: vars.baseURL + '/api/v2/users/update/email/finish?'+ params ,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: {
          email: email,
          code: code
        }
    })
  }

  export function getUserContent (userId) {
    const params = new URLSearchParams({userId: userId})
    return axios({
        method: 'GET',
        url: vars.baseURL + '/api/v2/college/images/user/user_generated?' + params,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: {}
    })
  }

  export function deleteUserContent (userId, imageId) {
    const params = new URLSearchParams({userId: userId, imageId: imageId})
    return axios({
        method: 'DELETE',
        url: vars.baseURL + '/api/v2/college/images/user/user_generated?' + params,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: {}
    })
  }

  export function  getAvailability (id) {
    const params = new URLSearchParams({userId: id, timezone: 'UTC'})
    return axios({
      method: 'GET',
      url: vars.baseURL + '/api/v2/scheduling/calendar/availability/retrieve?' + params,
      headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
      data: {}
    })
  }

  export function  getAvailabilityLocal (id) {
    const params = new URLSearchParams({userId: id, timezone: getTimezone()})
    return axios({
      method: 'GET',
      url: vars.baseURL + '/api/v2/scheduling/calendar/availability/local/retrieve?' + params,
      headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
      data: {}
    })
  }

  export function  setAvailability (id, availability) {
    const params = new URLSearchParams({userId: id, timezone: getTimezone()})
    return axios({
      method: 'POST',
      url: vars.baseURL + '/api/v2/scheduling/calendar/availability/set?' + params,
      headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
      data: {
        userId: id,
        availability: availability
      }
    })
  }

  export function updateTimezone (userId, timezone) {
    return axios({
        method: 'POST',
        url: vars.baseURL + '/api/v2/scheduling/calendar/timezone/update',
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: {
          userId: userId,
          timezone: timezone
        }
    })
  }

  export function validateReferralCode (code) {
    const params = new URLSearchParams({referrer: code})
    return axios({
        method: 'GET',
        url: vars.baseURL + '/api/v2/referrals/referrer/exists?' + params,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: {}
    })
  }

  export function getReferrals (userId) {
    const params = new URLSearchParams({userId: userId})
    return axios({
        method: 'GET',
        url: vars.baseURL + '/api/v2/referrals/referrer/all?' + params,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: {}
    })
  }

// attribute = [“skills” | “clubs” | “accomplishments” | “job_positions” | “experiences”]

// data = {
  // Skill
  // skill: String,

  // Club
  // club: String,

  // Accomplishment
  // title: String,
  // program: String
  // date: yyyy-mm-dd
  // time: hh:mm

  // Job Position, Experience
  // details: String,
  // role: String,
  // startDate: yyyy-mm-dd,
  // endDate: yyyy-mm-dd,
  // }

  export function addAttribute (userId, attribute, data) {
    return axios({
        method: 'POST',
        url: vars.baseURL + '/api/v2/users/' + userId + '/' + attribute,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: data
    })
  }

  // attribute = [“skills” | “clubs” | “accomplishments” | “job_positions” | “experiences”]
  export function removeAttribute (userId, attribute, attributeId) {
    return axios({
        method: 'DELETE',
        url: vars.baseURL + '/api/v2/users/' + userId + '/' + attribute + '/' + attributeId,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
    })
  }

  // attribute = [“skills” | “clubs” | “accomplishments” | “job_positions” | “experiences”]
  export function getAttribute (userId, attribute) {
    return axios({
        method: 'GET',
        url: vars.baseURL + '/api/v2/users/' + userId + '/' + attribute,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: {}
    })
  }

//   Params: userId, query
export function searchUsers(query, userId) {
  const params = new URLSearchParams({query: query, userId: userId})
  return axios({
      method: 'GET',
      url: vars.baseURL + '/api/v2/users/cached?' + params,
      headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
      data: {}
  })
}

// GET /api/v2/discovery/mentors
//   Params: userId, query
export function searchMentors(query, userId) {
  const params = new URLSearchParams({query: query, userId: userId})
  return axios({
      method: 'GET',
      url: vars.baseURL + '/api/v2/discovery/mentors?' + params,
      headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
      data: {}
  })
}
