import axios from 'axios'
import * as vars from './vars';

  // data = {url: String, caption: String, type: VIDEO | PHOTO}
  export function submitUserContent (ipedsid, userId, data) {
    const params = new URLSearchParams({userId: userId, ipedsid: ipedsid})
    return axios({
        method: 'PUT',
        url: vars.baseURL + '/api/v2/college/images/user_generated/submission?' + params,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: data
    })
  }

  export function getUserContent (userId) {
    const params = new URLSearchParams({userId: userId})
    return axios({
        method: 'GET',
        url: vars.baseURL + '/api/v2/college/images/user/user_generated?' + params,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: {}
    })
  }

  export function  rotateUserClient (id, direction) {
    const params = new URLSearchParams({id: id, direction: direction})
    return axios({
        method: 'POST',
        url: vars.baseURL + '/api/v2/college/images/user_generated/rotate?' + params,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: {}
    })
  }

  export function deleteUserContent (userId, imageId) {
    const params = new URLSearchParams({userId: userId, imageId: imageId})
    return axios({
        method: 'DELETE',
        url: vars.baseURL + '/api/v2/college/images/user/user_generated?' + params,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: {}
    })
  }

  export function getAllBlogPosts () {
    return axios({
        method: 'GET',
        url: vars.baseURL + '/api/v2/college/blog/all',
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: {}
    })
  }

  export function  getBlogPost (uri, uuid) {
    const params = new URLSearchParams({uri: uri, uuid: uuid})
    return axios({
        method: 'GET',
        url: vars.baseURL + '/api/v2/college/blog/post?' + params,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: {}
    })
  }

  export function getBlogMetadata (id, uuid) {
    const params = new URLSearchParams({id: id, uuid: uuid})
    return axios({
        method: 'GET',
        url: vars.baseURL + '/api/v2/college/blog/metadata?' + params,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: {}
    })
  }

  export function likeBlogPost (id, uuid, like) {
    const params = new URLSearchParams({id: id})
    return axios({
        method: 'POST',
        url: vars.baseURL + '/api/v2/college/blog/like?' + params,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: {
          like: like,
          uuid: uuid
        }
    })
  }

 export function dislikeBlogPost (id, uuid, dislike) {
    const params = new URLSearchParams({id: id})
    return axios({
        method: 'POST',
        url: vars.baseURL + '/api/v2/college/blog/dislike?' + params,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: {
          dislike: dislike,
          uuid: uuid
        }
    })
  }

