import axios from 'axios'
import * as vars from './vars';

export function logBlogImpression (name, impression, uuid) {
      return axios({
          method: 'PUT',
          url: vars.baseURL + '/api/v1/blog/impression',
          headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
          data: {
            name: name,
            impression: impression,
            uuid: uuid
          }
      }) 
  }

  export function logSearchMetric (ipedsid, userId) {
        var params;
        if (userId) {
            params = new URLSearchParams({ipedsid: ipedsid, userId: userId})
        } else {
            params = new URLSearchParams({ipedsid: ipedsid})
        }
        return axios({
            method: 'PUT',
            url: vars.baseURL + '/api/v1/college/search/metric?' + params,
            headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
            data: {}
        })
    }

    export function getAllCollegeSearchMetrics () {
        return axios({
            method: 'GET',
            url: vars.baseURL + '/api/v1/college/search/metrics/all',
            headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
            data: {}
        })
    }

    export function getRankedCollegeSearchMetrics () {
        return axios({
            method: 'GET',
            url: vars.baseURL + '/api/v1/college/search/metrics/ranked',
            headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
            data: {}
        })
    }

    export function getCollegeSearchMetricsByStudent (userId) {
        return axios({
            method: 'GET',
            url: vars.baseURL + '/api/v1/college/search/metrics/student?userId=' + userId,
            headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
            data: {}
        })
    }

    export function getCollegeSearchMetricsBySchool (ipedsid) {
        return axios({
            method: 'GET',
            url: vars.baseURL + '/api/v1/college/search/metrics/school?ipedsid=' + ipedsid,
            headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
            data: {}
        })
    }

    // metric - students|meetings|ratings|favorites|searches
    export function getMetrics(organization, metric, userId, ipedsid) {
        var params = organization === 587444773 ? "" : new URLSearchParams({userId: userId}) + '&'
        if (ipedsid) {
            params += new URLSearchParams({ipedsid: ipedsid})
        }
        return axios({
            method: 'GET',
            url: vars.baseURL + `/api/v1/counsel/${organization}/metrics/${metric}?` + params,
            headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
            data: {}
        })
    }

