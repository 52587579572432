var moment = require('moment-timezone');
 
export function militaryToStandard(time){
  return moment(time, 'HH:mm').format('h:mm a')
  }

export function standardToMiltary(time){
    const startTime = time.split(new RegExp(' '))[0];
    const timeOfDay = time.slice(-2);
    const hoursMinutes = startTime.split(new RegExp(':'));
    var hours = parseInt(hoursMinutes[0])

    if (timeOfDay === 'pm') {
      if (hours < 12) {
        hours += 12;
      }
    }

    if (timeOfDay === 'am') {
      if (hours === 12) {
        hours-=12;
      }
    }
    return hours.toString().padStart(2, '0') + ":" + hoursMinutes[1].slice(0,2);
  }

export function getPrettyDate(dateString){
    const date = dateString.split(' ')
    return date[0] + ' ' + date[1] + ' ' + date[2] + ', ' + date[3] + ' '
  }


export function stringToMoment(date, time, timezone) {
  return moment(`${date} ${time}`).tz(timezone).locale('en');
}

export function adminStringToMoment(date, time, timezone) {
    return moment(`${date} ${time}`).tz('America/New_York').locale('en');
}

//Input new Date()
//Output 'MM/DD/YYYY'
export function dateObjToString(dateObj){
  return moment(dateObj).locale('en').format('MM/DD/YYYY');
}

export function padDateString(date){
  return date
}

// Returns formatted datetime without converting it to a timezone
export function stringToFormatMomentNoTz(date, time){
  if(time){
    return moment(`${date.replace(/-/g, "/")} ${time}`).locale('en').format("dddd, MMMM Do YYYY, h:mm a");
  }
  return moment(`${date.replace(/-/g, "/")} ${time}`).locale('en').format("dddd, MMMM Do YYYY");
}

// Creates Moment obj in local tz and converts it to specified timezone
export function stringToFormatMoment(date, time, timezone){

  if(time){
    return moment(`${date.replace(/-/g, "/")} ${time}`).locale('en').tz(timezone).format("dddd, MMMM Do YYYY, h:mm a");
  }
  return moment(`${date.replace(/-/g, "/")} ${time}`).locale('en').tz(timezone).format("dddd, MMMM Do YYYY");
}

// Creates Moment obj in utc tz and converts it to specified timezone
export function stringToMomentUTC(date, time, timezone){
  if(time){
    return moment.utc(`${date.replace(/-/g, "/")} ${time}`).tz(timezone).locale('en')
  }
  return moment.utc(`${date.replace(/-/g, "/")}`).tz(timezone)
}

// Creates Moment obj in utc tz and converts it to specified timezone
export function adminStringToMomentUTC(date, time, timezone){
    if(time){
        return moment.utc(`${date.replace(/-/g, "/")} ${time}`).tz('America/New_York').locale('en')
    }
    return moment.utc(`${date.replace(/-/g, "/")}`).tz('America/New_York')
}

// Takes Moment obj and formats the date and time
export function formatMoment(momentObj){
  return  [momentObj.format("dddd, MMMM Do YYYY") , momentObj.format("h:mm a")]
}

/**
 * Input: Date, time (UTC)
 * Output: Duration from current time to input time
 *  */ 
export const calculateTimeUntilDuration = (date, time) => {

      var input = moment.utc(`${date.replace(/-/g, "/")} ${time}`)
      var now = new moment.utc()
      var duration = moment.duration(input.diff(now))
      return duration;
    };

/**
 * Input: Date, time (UTC)
 * Output: Days, hours, mins dictionary from current time to input time 
 *  */ 
export const calculateTimeUntil = (date, time) => {
      const duration = calculateTimeUntilDuration(date,time)._data;

      let timeLeft = {};

        timeLeft = {
          days: duration.days,
          hours: duration.hours,
          minutes: duration.minutes,
          seconds: duration.seconds,
        };

      return timeLeft;
    };
  
/**
 * Input: Date, time (UTC)
 * Output: Days, hours, mins dictionary from current time to input time 
 * Calculates time until end of meeting from current time
 *  */ 
export const calculateTimeUntilEnd = (date, time, meetingDuration) => {
  var input = moment.utc(`${date.replace(/-/g, "/")} ${time}`).add(meetingDuration || 60,'minutes')
  var now = new moment.utc()
  var duration = moment.duration(input.diff(now))._data

  let timeLeft = {};

    timeLeft = {
      days: duration.days,
      hours: duration.hours,
      minutes: duration.minutes,
      seconds: duration.seconds,
    };

  return timeLeft;
};
  
  /**  
   * Date.UTC(year[, month[, day[, hour[, minute[, second[, millisecond]]]]]]) (where month is zero based)
   * Ref : https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/UTC 
  */
  export const utcToLocal = (date, time, timezone) => {
    if(!timezone) {
      timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    const dateArr = date.split("-")
    const timeArr = time.split(":")
    const utcDate = new Date(Date.UTC(dateArr[0], parseInt(dateArr[1]) - 1, dateArr[2], timeArr[0], timeArr[1], 0));
    return utcDate.toLocaleString("en-US",{timeZone: timezone});
  };

export const isFutureMeeting = (date, time) => {
      var meetingDatetime = moment.utc(`${date} ${time}`).add(1,'hours');
      var now = new moment.utc();

      return moment(now).isBefore(meetingDatetime);
    };

export const isActiveMeeting = (date, time, duration) => {
      var meetingStartTime = moment.utc(`${date} ${time}`);
      var meetingEndTime = moment.utc(`${date} ${time}`).add(duration || 60,'minutes');
      var now = new moment.utc();
      return moment(now).isBefore(meetingEndTime) && moment(now).isAfter(meetingStartTime);
    };

export const isPastMeeting = (date, time, duration) => {
      var meetingDatetime = moment.utc(`${date} ${time}`).add(duration || 60,'minutes');
      var now = new moment.utc();
      return moment(now).isAfter(meetingDatetime);
    };

export const boolIdxToTime = (idx) => {
      var minutes = idx % 2 === 0 ? "00" : "30";
      let index = Math.floor(idx/2) ;
      var hour = index % 24;

      return militaryToStandard(`${hour}:${minutes}`)
// idx   // 01  23 45 67 89 1011 1112 1314 1516 1718 1920 2122 2324 2526 2728 2930 3132 3334 3536 3738 3940 4142  4344  4546  47
// index  // 0  1  2  3  4  5    6     7     8   9     10   11 12   13    14  15    16  17  18    19  20    21    22   23     24
// time //  12  1  2  3  4  5    6     7     8   9     10   11 12   1      2   3     4   5   6   7     8     9     10    11    12
    };

export const boolArrToAvailRange = (arr) => {
  var ranges = [];
  var index = 0;
  while (index < arr.length) {
    if(arr[index]){
        var rangeStartIndex = index;
        while (arr[index + 1]) {
            // continue until the range ends
            index++;
        }
        let endtime = index === arr.length - 1 ? 0 : index + 1
        if (rangeStartIndex === index) {
            ranges.push([boolIdxToTime(index), boolIdxToTime(endtime)]);
        } else {
            ranges.push([boolIdxToTime(rangeStartIndex),boolIdxToTime(endtime)]);
        }
      }
        index++;
    }
    return ranges;
}

// Get list of dates in the following week
export function getWeek(timezone){
  var dates = [];
   const today = moment().tz(timezone).locale('en');
   for(let i=0; i< 7; i++ ){
     const from_date = today.clone().startOf('week');
     dates.push(from_date.add(i,'days'));
      }
   return dates;
 }

 export const timestampToDiff = (timestamp) => {
  var dateString = moment.utc(timestamp).locale('en').format('MM/DD/YY HH:mm');

  var diff = calculateTimeUntil(dateString.split(" ")[0], dateString.split(" ")[1]);
  if(diff.hours === 0 && diff.days === 0 && diff.minutes === 0){
      return Math.abs(diff.seconds) + `sec`
  } else if(diff.hours === 0 && diff.days === 0 && diff.minutes !== 0){
    return Math.abs(diff.minutes) + `min`
  } else if( diff.days === 0 && diff.hours !== 0 && diff.minutes !== 0){
    return Math.abs(diff.hours) + `hr`
  } else {
    return Math.abs(diff.days) + `day${diff.days < -1 ? 's' : ''}`
  }
}

export function getCurrentWeek() {
  var currentDate = moment().locale('en');

  var weekStart = currentDate.clone().startOf('isoWeek');
  var weekEnd = currentDate.clone().endOf('isoWeek');

  var days = [];

  for (var i = 0; i <= 6; i++) {
    days.push(moment(weekStart).add(i, 'days').format("dddd MMM DD, YYYY"));
  }
  return days
}

export const times = [
    '12:00 am',
    '12:30 am',
    '1:00 am',
    '1:30 am',
    '2:00 am',
    '2:30 am',
    '3:00 am',
    '3:30 am',
    '4:00 am',
    '4:30 am',
    '5:00 am',
    '5:30 am',
    '6:00 am',
    '6:30 am',
    '7:00 am',
    '7:30 am',
    '8:00 am',
    '8:30 am',
    '9:00 am',
    '9:30 am',
    '10:00 am',
    '10:30 am',
    '11:00 am',
    '11:30 am',
    '12:00 pm',
    '12:30 pm',
    '1:00 pm',
    '1:30 pm',
    '2:00 pm',
    '2:30 pm',
    '3:00 pm',
    '3:30 pm',
    '4:00 pm',
    '4:30 pm',
    '5:00 pm',
    '5:30 pm',
    '6:00 pm',
    '6:30 pm',
    '7:00 pm',
    '7:30 pm',
    '8:00 pm',
    '8:30 pm',
    '9:00 pm',
    '9:30 pm',
    '10:00 pm',
    '10:30 pm',
    '11:00 pm',
    '11:30 pm',
]

export function sortMeetings (meetingList) {
  var sorted = {
    past:[],
    requests:[],
    future: [],
    canceled: [],
  }
  meetingList.forEach(meeting => {
    if( meeting.status.toUpperCase() === 'PROPOSED'){
       sorted.requests.push(meeting)
    }
     if ( meeting.status.toUpperCase() === 'SCHEDULED') {
       sorted.future.push(meeting)
    }
     if (meeting.status.toUpperCase() === 'COMPLETED'  ){
       sorted.past.push(meeting)
    }
    if (meeting.status.toUpperCase() === 'CANCELED' || meeting.status.toUpperCase() === 'CANCELLED'  ){
      sorted.canceled.push(meeting)
    }
  })
  const past = sorted.past.sort((a, b) => stringToMoment(b.date || b.interviewDate,b.startTime || b.interviewTime, getTimezone()) - stringToMoment(a.date || a.interviewDate, a.startTime || a.interviewTime, getTimezone()))
  const future = sorted.future.sort((a, b) => stringToMoment(b.date || b.interviewDate,b.startTime || b.interviewTime, getTimezone()) - stringToMoment(a.date || a.interviewDate, a.startTime || a.interviewTime, getTimezone()))
  const requests = sorted.requests.sort((a, b) => stringToMoment(b.date || b.interviewDate,b.startTime || b.interviewTime, getTimezone()) - stringToMoment(a.date || a.interviewDate, a.startTime || a.interviewTime, getTimezone()))
  const canceled = sorted.canceled.sort((a, b) => stringToMoment(b.date || b.interviewDate,b.startTime || b.interviewTime, getTimezone()) - stringToMoment(a.date || a.interviewDate, a.startTime || a.interviewTime, getTimezone()))

  const res = {
    past: past,
    requests:requests,
    future: future,
    canceled: canceled,
  }
  return sorted;
}

export function sortInterviews (interviewList) {
  var sorted = {
    past:[],
    requests:[],
    future: [],
    canceled: [],
  }


  interviewList.forEach(interview => {
    if( interview.status.toUpperCase()  === 'PROPOSED'){
      sorted.requests.push(interview)
    }
     if ( interview.status.toUpperCase()  === 'SCHEDULED') {
       sorted.future.push(interview)
    }
     if (interview.status.toUpperCase()  === 'COMPLETED'  ){
       sorted.past.push(interview)
    }
    if (interview.status.toUpperCase()  === 'CANCELED'  ){
      sorted.canceled.push(interview)
    }
  })

  const past = sorted.past.sort((a, b) => stringToMoment(b.interviewDate,b.interviewTime, getTimezone()) - stringToMoment(a.interviewDate,a.interviewTime, getTimezone()))
  const future = sorted.future.sort((a, b) => stringToMoment(b.interviewDate,b.interviewTime, getTimezone()) - stringToMoment(a.interviewDate,a.interviewTime, getTimezone()))
  const requests = sorted.requests.sort((a, b) => stringToMoment(b.interviewDate,b.interviewTime, getTimezone()) - stringToMoment(a.interviewDate,a.interviewTime, getTimezone()))
  const canceled = sorted.canceled.sort((a, b) => stringToMoment(b.interviewDate,b.interviewTime, getTimezone()) - stringToMoment(a.interviewDate,a.interviewTime, getTimezone()))
  return sorted;
}

export function getTimezone(){
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function formatIso(date, startTime){
  var moment = require('moment-timezone');
  const dateString = date + 'T' + startTime + ':00.000'
  const res = moment.utc(dateString).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).locale('en').format("dddd, MMMM Do YYYY, h:mm a");
  return res
}