import React, { useState, useRef } from 'react'
import { Root } from '../../Api/Root'
import s from '../../Pages/Landing/styles/Hermes.module.scss';

import quotation_mark from '../../Pages/Landing/static/quotation_mark.svg'
import reverse_quotation_mark from '../../Pages/Landing/static/quotation_mark.svg'

export const TestimonySwitcher = ({containerClassName}) => {
    const [index, setIndex] = useState(0)
    const [activeId, setActiveId] = useState('btn_one')
    const testimonies = [
      {
        quote: "Game changer. Hermes cuts through the clutter and gives me the key points fast. It's like having a built-in cheat sheet for every webpage I visit. I no longer get bogged down in details!",
        author: "Max Ramirez, Busy Entrepreneur"
      },
      {
        quote: "Hermes? More like 'Her-mazing'! This thing is a game-changer for research. No more drowning in PDFs and combing through websites. Hermes lets me blast through articles, snag the good stuff, and move on to the next one.",
        author: "Dr. Chris Thompson, History Buff"
      },
      {
        quote: "As a student entrepreneur, time is my most valuable asset.  Hermes allows me to quickly grasp the key takeaways from industry articles and reports, freeing me up to focus on analysis and application. It's become an essential tool in my startup toolkit.",
        author: "Taylor Schmidt, Corbu Founder & CEO"
      }
    ]

      function switchTestimony (btn_id) {
        var currentlyActiveButton = document.getElementById(activeId);
        currentlyActiveButton.style.backgroundColor = '#797979';

        document.getElementById(btn_id).style.backgroundColor = "#BE2A52";
        setActiveId(btn_id);

        if (btn_id === 'btn_one') {
          setIndex(0);
        } else if (btn_id === 'btn_two') {
          setIndex(1);
        } else if (btn_id === 'btn_three') {
          setIndex(2);
        }
      }

    return <div className={containerClassName}>
            <div className={`${s.quotationMark}`}>
              <img src={quotation_mark}/>
            </div>
            <div className={`${s.testimonialContainer}`}>
              <p className={`text-center fs-20 mb-4 ${s.testimonialSubtitle}`}>"{testimonies[index].quote}"</p>
            </div>
            <div className={`${s.testimonialContainer}`}>
              <p className={`text-center fs-14 mb-4 ${s.testimonialSubtitle}`}>{testimonies[index].author}</p>
            </div>
            <div className={`${s.testimonialSwitcher}`}>
              <div id='btn_one' className={`${s.testimonialSwitcherButton}`} onClick={() => switchTestimony('btn_one')} />
              <div id='btn_two' className={`${s.testimonialSwitcherButton}`} onClick={() => switchTestimony('btn_two')} />
              <div id='btn_three' className={`${s.testimonialSwitcherButton}`} onClick={() => switchTestimony('btn_three')} />
            </div>
          </div>
}
