
import axios from 'axios';
import * as vars from './vars';

export function contactSupport (email, name, subject, message){
    return axios({
        method: 'POST',
        url: vars.baseURL + '/api/v1/support/form_contact',
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data : {
        email: email,
        name: name,
        subject: subject,
        message: message,
        }
    })
}

export function subscribeToNewsletter (email) {
    return axios({
        method: 'POST',
        url: vars.baseURL + '/api/v1/newsletter/subscription',
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data : {
        email: email,
        }
    })
}

export function acknowledgeDownload () {
    return axios({
        method: 'GET',
        url: vars.baseURL + '/api/v1/registration/onboard',
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken}
    })
}

export function acknowledgeUpdate () {
    return axios({
        method: 'GET',
        url: vars.baseURL + '/api/v1/registration/update',
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken}
    })
}

export function logChromeStoreClick (ipAddress, timezone, tiktokClickId = null) {
  let deviceId = (Math.random() + 1).toString(36).substring(7);
  const data = {
    action: 'click',
    deviceId: deviceId,
    ipAddress: ipAddress,
    timezone: timezone,
  };

  if (!!tiktokClickId) {
    data['tiktokClickId'] = tiktokClickId;
  }

  return axios({
      method: 'POST',
      url: vars.baseURL + '/api/v1/metrics/chrome_store',
      headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
      data: data
  })
}

export function logReferralLinkClick (referralId, timezone) {
    return axios({
        method: 'POST',
        url: vars.baseURL + '/api/v1/metrics/referralLinkClick',
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data : {
          referralId: referralId,
          timezone: timezone,
        }
    })
}
