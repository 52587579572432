import * as Meeting from './Utils/meeting';
import * as College from './Utils/college';
import * as Payment from './Utils/payment';
import * as Metrics from './Utils/metrics';

const merged = {
  ...Meeting,
  ...College,
  ...Payment,
  ...Metrics
}

export default merged;