import React from 'react';
import { NavBar } from '../../Components/Nav/NavBar';
import s from './styles/About.module.scss';
import Footer from './Footer';
import h from './styles/Home.module.scss';
import Card from 'react-bootstrap/Card';

const About = () => (
  <>
    <div className={s.about}>
    <NavBar style={{ backgroundColor:'#FFFFFF'}}/>
      <div className={s.sectionOne}>
        <div className={`${s.container}`}>
        
        </div>
      </div>
    </div>
    <Footer />
  </>
);


{/* <div className={`${s.sectionTwo} ${h.partner}`}>
<h2 className='text-center my-5'>Why NXSTEP</h2>
<div className={h.gridThreeCol}>
<Card className={h.card}>
  <Card.Body>
    <Card.Title style={{fontSize:'24px'}}><strong>Great Value</strong></Card.Title>
    <Card.Text className='mt-4' style={{lineHeight:'30px', fontSize:'18px',}}>
    Work with highly motivated and enthusiastic freelancers who want nothing else but to leave the best impression for their clients while building their resume in the process- all at a price point that's right for your company.
    </Card.Text>
  </Card.Body>
</Card>
<Card className={h.card}>
  <Card.Body>
    <Card.Title style={{fontSize:'24px'}}><strong>Streamlined Hiring</strong></Card.Title>
    <Card.Text className='mt-4' style={{lineHeight:'30px', fontSize:'18px',}}>
    From posting a project to accepting applications in just a few days, NXSTEP provides you with everything necessary to seamlessly scout, interview and communicate with your new hire every step of the way            </Card.Text>
  </Card.Body>
</Card>
<Card className={h.card}>
  <Card.Body>
    <Card.Title style={{fontSize:'24px'}}><strong>Flexible Contracts</strong></Card.Title>
    <Card.Text className='mt-4' style={{lineHeight:'30px', fontSize:'18px',}}>
    Whether you're looking to pay your hire a fixed payment, commission, or on an hourly basis- We support all kinds of flexible and remote contracts to suit all kinds of needs
    </Card.Text>
</Card.Body>
</Card>
</div>
</div> */}

export default (About);
