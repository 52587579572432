import React from 'react';
import { NavBar } from '../../Components/Nav/NavBar';
import s from './styles/Blog.module.scss';
import Footer from './Footer';
import { Helmet } from 'react-helmet';

const Privacy = () => (
  <>
  <Helmet>
      <title>Privacy - Cassy</title>
    </Helmet>
    <div className={s.blog}>
    <NavBar style={{ backgroundColor:'#FFFFFF'}}/>
      <div className={s.sectionOne}>
        <div className={`${s.container}`}>
          <h1><strong>Hermes Privacy Policy</strong></h1>
        </div>
      </div>
      <div className={`${s.sectionTwo} ${s.container} `}>
        <h2>Introduction</h2>
        <p>This Privacy Policy describes how Cassy ("we," "us," or "our")
        collects, uses, and discloses your information when you use our Chrome
        extension, Hermes (the "Extension").</p>

        <h2 style={{paddingTop:"15px"}}>Information We Collect</h2>
        <p>Hermes records the websites you visit to provide context and
        improve the accuracy and relevance of its features. This data is
        anonymized and does not contain any Personally Identifiable
        Information (PII) such as your name, email address, or IP address.
        We cannot identify you from the website records we collect.</p>

        <h2 style={{paddingTop:"15px"}}>Information We Collect</h2>
        <ul>
          <li><strong>Website URLs:</strong> Hermes temporarily stores the URLs of the websites
          you visit to understand how you interact with the extension and
          provide better results.</li>
        </ul>

        <h2 style={{paddingTop:"15px"}}>What we do not collect</h2>
        <ul>
          <li><strong>Browsing History:</strong> Hermes does not track or record
          your overall browsing history or any additional details beyond the
          website URLs you visit while using the extension.
          </li>
          <li><strong>Personally Identifiable Information (PII):</strong> We
          do not collect any information that can directly identify you.</li>
        </ul>

        <h2 style={{paddingTop:"15px"}}>How We Use Your Information</h2>
        <p>We use the anonymized website data we collect for the sole purpose
        of improving the Hermes extension:</p>
        <ul>
          <li>To enhance the performance and effectiveness of features that rely on context.</li>
          <li>To identify and address bugs or errors more efficiently.</li>
          <li>To gain insights into user interactions and usage patterns (anonymously) to improve the overall user experience.</li>
        </ul>

        <h2 style={{paddingTop:"15px"}}>Data Retention</h2>
        <p>The anonymized website records we collect are temporary and
        automatically deleted after 30 days. We do not store this data in a
        way that can be linked back to individual users.</p>

        <h2 style={{paddingTop:"15px"}}>Security</h2>
        <p>We take appropriate security measures to protect the anonymized
        website data we collect from unauthorized access, disclosure,
        alteration, or destruction. However, no internet transmission or
        electronic storage method is 100% secure.</p>

        <h2 style={{paddingTop:"15px"}}>Your Choices</h2>
        <p>You can choose to uninstall the Hermes extension at any time to
        discontinue its functionality and data collection.</p>

        <h2 style={{paddingTop:"15px"}}>Changes to This Privacy Policy</h2>
        <p>We may update our Privacy Policy from time to time. We will notify
        you of any changes by posting the new Privacy Policy within the
        extension or on our website.</p>

        <h2 style={{paddingTop:"15px"}}>Contact Us</h2>
        <p>If you have any questions about this Privacy Policy, please contact
        us at eddie@cassy.dev or visit our website at www.cassy.dev/hermes.</p>
        </div>
    </div>
    <Footer />
  </>
);

export default (Privacy);
