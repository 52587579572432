import React from 'react';
import { NavBar } from '../../Components/Nav/NavBar';
import s from './styles/Blog.module.scss';
import Footer from './Footer';
import { Helmet } from 'react-helmet';

const TermsOfService = () => (
  <>
  <Helmet>
      <title>Terms of Service - Hermes</title>
    </Helmet>
    <div className={s.blog}>
    <NavBar style={{ backgroundColor:'#FFFFFF'}}/>
      <div className={`${s.sectionOne}`}>
        <div className={`${s.container}`}>
          <h1><strong>Hermes Terms and Conditions</strong></h1>
        </div>
      </div>
      <div className={`${s.sectionTwo} ${s.container} `}>
      <h2>1. Introduction</h2>
      <p>Welcome to <strong>Cassy Technology Company</strong> ("Cassy", "Company", "we", "our", "us") These Terms & Conditions (“Terms”, “Terms & Conditions”) govern your use of our website located at <strong>https://cassy.dev/</strong> (“Service/Website/Webpage”) operated by <strong>Cassy
Technology Solutions Co.</strong></p>
      <p>Our Privacy Policy also governs your use of our Service and explains how we collect, safeguard and disclose information that results from your use of our web pages. Your agreement with us includes these Terms and our Privacy Policy (“Agreements”). You acknowledge that you have read and understood Agreements, and agree to be bound of them. If you do not agree with (or cannot comply with) Agreements, then you may not use the Website, but please let us know by emailing at <strong>support@cassy.dev</strong> so we can try to find a solution. These Terms apply to all visitors, users and others who wish to access or use Website.</p>

      <h2 style={{paddingTop:"15px"}}>2. Communications</h2>

      <p>By using our Service, you agree to subscribe to newsletters, marketing or promotional materials and other information we may send. However, you may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or by emailing
at support@cassy.dev.</p>

      <h2 style={{paddingTop:"15px"}}>3. Registration on the Website</h2>
      <p>In order to access the Website and use the services through the Website, the User shall be required to register an account on the Website. Cassy reserves the sole right to terminate or reject registration of any account on the Website, for whatsoever reason. The User agrees that if the account is being created on behalf of another company, then you are the authorized representative of such company. Furthermore, you may also be required by the Company to provide evidence in relation to the same.</p>

      <h2 style={{paddingTop:"15px"}}>4. Profile of the Account</h2>
      <p>The Users who are willing to create an account on the Website are required to create a profile based on their account. The process of creating a profile must be completed on the Website. The User agrees and acknowledges that the profile and content available on the profile shall also be available for other Users on the Website. The User agrees to provide information that is true, accurate, precise and complete in itself. Furthermore, it is
important to note that any misleading or incorrect information may lead to termination of the account from the Website.</p>
      <p>The Users acknowledge that the Company has the sole right to terminate and suspend the privileges that are being offered through the Website, in case any misleading or incorrect information is found on the Website.</p>
      <p>There are different types of accounts available on the Website. Together each account shall be referred as (“Users”). The types of account that can be registered on the Website have been listed below:</p>
      <h3 style={{paddingTop:"15px", textAlign:"left"}}>Company Account</h3>
      <p>The Users can opt to use the Website as a Company and register with the Website as a Company. The User in this option shall also be entitled to allow one of its authorized representative to use the account on behalf of the Company. The Company shall further
have allowed to post several projects on the Website for the purpose of hiring freelancers.</p>
      <h3 style={{paddingTop:"15px", textAlign:"left"}}>Student Account</h3>
      <p>You will also have an option to register on the Website for a student account. Under this type of account registration, it is necessary for the User to be enrolled in an undergraduate or postgraduate program with an accredited university, or shall also be entitled to registered an account if such User has explicit permission from the Company. The students who will register as student account may be hired by different companies for freelance work. However, the Company or the Website shall not have any liability with respect to any loss of profit, expected earnings etc.</p>

      <h2 style={{paddingTop:"15px"}}>5. Usernames and Passwords</h2>
      <p>While registration of the account on the Website, you will be required to provide proof of validation and proof of identity. The account shall only be registered once the required documents have been provided and proof of identity is confirmed. Furthermore, while creating an account, the User will also be required to opt for a profile name and password. When you register for an Account, you will be asked to choose a username and password for the Account. The User shall be solely responsible for the maintence and security of such account. The Website shall not have any liability with respect to the same. The User is not allowed to share the password or user name of the account with any other person who has not been granted the right to use the account by the Company. The User is under an obligation to inform the Company immediately in case any such offence is suspected where any other party is using the account.</p>

      <h2 style={{paddingTop:"15px"}}>6. Purchases</h2>
      <p>If you wish to purchase any service made available through Website (“Purchase”), you may be asked to supply certain information relevant to your Purchase including but not limited to, your credit or debit card number, the expiration date of your card, your billing address, and your shipping information.</p>
      <p>You represent and warrant that:</p>
      <ol>
      <li style={{paddingBottom:"15px"}}>you have the legal right to use any card(s) or other payment method(s) in connection with any Purchase; and that</li>
      <li>the information you supply to us is true, correct and complete.</li>
      </ol>
      <p>We may employ the use of third party services for the purpose of facilitating payment and the completion of Purchases. By submitting your information, you grant us the right to provide the information to these third parties subject to our Privacy Policy.</p>
      <p>We reserve the right to refuse or cancel your Purchase at any time for reasons including but not limited to: product or service availability, errors in the description or price of the product or service, error in your order or other reasons. We reserve the right to refuse or cancel your order if fraud or an unauthorized or illegal transaction is suspected.</p>

      <h2 style={{paddingTop:"15px"}}>7. Contests, Sweepstakes, and Promotions</h2>
      <p>Any contests, sweepstakes or other promotions (collectively, “Promotions”) made
available through Service may be governed by rules that are separate from these Terms of Service. If you participate in any Promotions, please review the applicable rules as well as our Privacy Policy. If the rules for a Promotion conflict with these Terms of Service, Promotion rules will apply.</p>
      <h2 style={{paddingTop:"15px"}}>8. Content</h2>
      <p>Our Website allows you to post, link, store, share and otherwise make available certain information, text, graphics, videos, or other material (“Content”). You are responsible for Content that you post on or through Website, including its legality, reliability, and appropriateness.</p>
      <p>By posting Content on or through Website, You represent and warrant that:</p>
      <ol>
      <li style={{paddingBottom:"15px"}}>Content is yours (you own it) and/or you have the right to use it and the right to grant us the rights and license as provided in these Terms, and</li>
      <li>that the posting of your Content on or through Website does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person or entity. We reserve the right to terminate the account of anyone found to be infringing on a copyright.</li>
      </ol>
      <p>You retain any and all of your rights to any Content you submit, post or display on or through Website and you are responsible for protecting those rights. We take no responsibility and assume no liability for Content you or any third party posts on or through Service. However, by posting Content using Service you grant us the right and license to use, modify, publicly perform, publicly display, reproduce, and distribute such Content on and through Service. You agree that this license includes the right for us to make your Content available to other users of Website, who may also use your Content subject to these Terms.</p>
      <p>Cassy Technology Company has the right but not the obligation to monitor and edit all Content provided by users. In addition, Content found on or through this Website are the property of Cassy Technology Company or used with permission. You may not
distribute, modify, transmit, reuse, download, repost, copy, or use said Content, whether in whole or in part, for commercial purposes or for personal gain, without express advance written permission from us.</p>
      <h2 style={{paddingTop:"15px"}}>9. Prohibited Uses</h2>
      <p>You may use Website only for lawful purposes and in accordance with Terms. You agree not to use Website:</p>
      <ol>
      <li style={{paddingBottom:"15px"}}>In any way that violates any applicable national or international law or regulation.</li>
      <li style={{paddingBottom:"15px"}}>For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content or otherwise.</li>
      <li style={{paddingBottom:"15px"}}>To transmit, or procure the sending of, any advertising or promotional material, including any “junk mail”, “chain letter,” “spam,” or any other similar solicitation.</li>
      <li style={{paddingBottom:"15px"}}>To impersonate or attempt to impersonate Company, a Company employee, another user, or any other person or entity.</li>
      <li style={{paddingBottom:"15px"}}>In any way that infringes upon the rights of others, or in any way is illegal, threatening, fraudulent, or harmful, or in connection with any unlawful, illegal, fraudulent, or harmful purpose or activity.</li>
      <li>To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of Service, or which, as determined by us, may harm or offend Company or users of Service or expose them to liability.</li>
      </ol>
      <p>Additionally, you agree not to:</p>
      <ol>
      <li style={{paddingBottom:"15px"}}>Use Website in any manner that could disable, overburden, damage, or impair Website or interfere with any other party’s use of Website, including their ability to engage in real time activities through Website.</li>
      <li style={{paddingBottom:"15px"}}>Use any robot, spider, or other automatic device, process, or means to access Website for any purpose, including monitoring or copying any of the material on Service.</li>
      <li style={{paddingBottom:"15px"}}>Use any manual process to monitor or copy any of the material on Website or for any other unauthorized purpose without our prior written consent.</li>
      <li style={{paddingBottom:"15px"}}>Use any device, software, or routine that interferes with the proper working of Website.</li>
      <li style={{paddingBottom:"15px"}}>Introduce any viruses, trojan horses, worms, logic bombs, or other material which is malicious or technologically harmful.</li>
      <li style={{paddingBottom:"15px"}}>Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of Website, the server on which Service is stored, or any server, computer, or database connected to Website.</li>
      <li style={{paddingBottom:"15px"}}>Attack Website via a denial-of-service attack or a distributed denial-of-service attack.</li>
      <li style={{paddingBottom:"15px"}}>Take any action that may damage or falsify Company rating.</li>
      <li>Otherwise attempt to interfere with the proper working of Website.</li>
      </ol>
      <p></p>
      <h2 style={{paddingTop:"15px"}}>10. Analytics</h2>
      <p>We may use third-party Service Providers to monitor and analyze the use of our Service.</p>
      <h2 style={{paddingTop:"15px"}}>11. No Use by Minors</h2>
      <p>Service is intended only for access and use by individuals at least sixteen (16) years old. By accessing or using Website, you warrant and represent that you are at least sixteen (16)
years of age and with the full authority, right, and capacity to enter into this agreement and abide by all of the terms and conditions of Terms. If you are not at least sixteen (16) years old, you are prohibited from both the access and usage of Website.</p>
      <h2 style={{paddingTop:"15px"}}>12. Accounts</h2>
      <p>When you create an account with us, you guarantee that you are above the age of 16, and that the information you provide us is accurate, complete, and current at all times. Inaccurate, incomplete, or obsolete information may result in the immediate termination of your account on Website.</p>
      <p>You are responsible for maintaining the confidentiality of your account and password, including but not limited to the restriction of access to your computer and/or account. You agree to accept responsibility for any and all activities or actions that occur under your account and/or password, whether your password is with our Website or a third-party
service. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.</p>
      <p>You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than you, without appropriate authorization. You may not use as a username any name that is offensive, vulgar or obscene. We reserve the right to refuse service, terminate accounts, remove or edit content, or cancel orders in our sole discretion.</p>
      <h2 style={{paddingTop:"15px"}}>13. Intellectual Property</h2>
      <p>Website and its original content (excluding Content provided by users), features and functionality are and will remain the exclusive property of Cassy Technology Company and its licensors. The Website is protected by copyright, trademark, and other laws of and foreign countries. Our trademarks may not be used in connection with any product or service without the prior written consent of Cassy Technology Company.</p>
      <h2 style={{paddingTop:"15px"}}>14. Copyright Policy</h2>
      <p>We respect the intellectual property rights of others. It is our policy to respond to any claim that Content posted on Website infringes on the copyright or other intellectual property rights (“Infringement”) of any person or entity.</p>
      <p>If you are a copyright owner, or authorized on behalf of one, and you believe that the copyrighted work has been copied in a way that constitutes copyright infringement, please submit your claim via email to support@cassy.dev, with the subject line: “Copyright Infringement” and include in your claim a detailed description of the alleged Infringement as detailed below, under “DMCA Notice and Procedure for Copyright Infringement Claims”. You may be held accountable for damages (including costs and attorneys’ fees) for misrepresentation or bad-faith claims on the infringement of any Content found on and/or through Website on your copyright.</p>
      <h2 style={{paddingTop:"15px"}}>15. DMCA NOTICE AND PROCEDURE FOR COPYRIGHT INFRINGEMENT CLAIMS</h2>
      <p>You may submit a notification pursuant to the Digital Millennium Copyright Act (DMCA) by providing our Copyright Agent with the following information in writing (see 17 U.S.C 512(c)(3) for further detail):</p>
      <ol>
      <li style={{paddingBottom:"15px"}}>an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright’s interest;</li>
      <li style={{paddingBottom:"15px"}}>a description of the copyrighted work that you claim has been infringed, including the URL (i.e., web page address) of the location where the copyrighted work exists or a copy of the copyrighted work;</li>
      <li style={{paddingBottom:"15px"}}>identification of the URL or other specific location on Website where the material that you claim is infringing is located;</li>
      <li style={{paddingBottom:"15px"}}>your address, telephone number, and email address;</li>
      <li style={{paddingBottom:"15px"}}>a statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law;</li>
      <li>a statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner’s behalf.</li>
      </ol>
      <p>You can contact our Copyright Agent via email at support@cassy.dev.</p>
      <h2 style={{paddingTop:"15px"}}>16. Error Reporting and Feedback</h2>
      <p>You may provide us either directly at support@cassy.dev or via third party sites and tools with information and feedback concerning errors, suggestions for improvements, ideas, problems, complaints, and other matters related to our Website (“Feedback”). You
acknowledge and agree that:</p>
      <ol>
      <li style={{paddingBottom:"15px"}}>you shall not retain, acquire or assert any intellectual property right or other right, title or interest in or to the Feedback;</li>
      <li style={{paddingBottom:"15px"}}>Company may have development ideas similar to the Feedback;</li>
      <li style={{paddingBottom:"15px"}}>Feedback does not contain confidential information or proprietary information
from you or any third party; and</li>
      <li>Company is not under any obligation of confidentiality with respect to the
Feedback. In the event the transfer of the ownership to the Feedback is not possible due to applicable mandatory laws, you grant Company and its affiliates an exclusive, transferable, irrevocable, free-of-charge, sub-licensable, unlimited and perpetual right to use (including copy, modify, create derivative works, publish, distribute and commercialize) Feedback in any manner and for any purpose.</li>
      </ol>
      <h2 style={{paddingTop:"15px"}}>17. Links to Other Websites</h2>
      <p>Our Website may contain links to third party web sites or services that are not owned or
controlled by Cassy Technology Company. Cassy. has no control over, and assumes no responsibility for the content, privacy policies, or practices of any third party web sites or services. We do not warrant the offerings of any of these entities/individuals or their websites.</p>
      <p>YOU ACKNOWLEDGE AND AGREE THAT COMPANY SHALL NOT BE RESPONSIBLE OR LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY DAMAGE OR LOSS CAUSED OR ALLEGED TO BE CAUSED BY OR IN CONNECTION WITH USE OF OR RELIANCE ON ANY SUCH CONTENT, GOODS OR SERVICES AVAILABLE ON OR THROUGH ANY SUCH THIRD PARTY WEB SITES OR SERVICES. WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND PRIVACY POLICIES OF ANY THIRD PARTY WEB SITES OR SERVICES THAT YOU VISIT.</p>
      <h2 style={{paddingTop:"15px"}}>18. Dicslaimer of Warranty</h2>
      <p>THESE SERVICES ARE PROVIDED BY COMPANY ON AN “AS IS” AND “AS AVAILABLE” BASIS. COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THEIR SERVICES, OR THE INFORMATION, CONTENT OR MATERIALS INCLUDED THEREIN. YOU EXPRESSLY AGREE THAT YOUR USE OF THESE SERVICES, THEIR CONTENT, AND ANY SERVICES OR ITEMS OBTAINED FROM US IS AT YOUR SOLE RISK.</p>
      <p>NEITHER COMPANY NOR ANY PERSON ASSOCIATED WITH COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE SERVICES. WITHOUT LIMITING THE FOREGOING, NEITHER COMPANY NOR ANYONE ASSOCIATED WITH COMPANY REPRESENTS OR WARRANTS THAT THE SERVICES, THEIR CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT THE SERVICES OR THE SERVER THAT MAKES IT
AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE SERVICES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.</p>
      <p>COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON- INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.</p>
      <p>THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>
      <h2 style={{paddingTop:"15px"}}>19. Limitation of Liability</h2>
      <p>EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR OFFICERS,
DIRECTORS, EMPLOYEES, AND AGENTS HARMLESS FOR ANY INDIRECT, PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGE, HOWEVER IT ARISES (INCLUDING ATTORNEYS’ FEES AND ALL RELATED COSTS AND EXPENSES OF LITIGATION AND ARBITRATION, OR AT TRIAL OR ON APPEAL, IF ANY, WHETHER OR NOT LITIGATION OR ARBITRATION IS INSTITUTED), WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS ACTION, OR ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, INCLUDING WITHOUT LIMITATION ANY CLAIM FOR PERSONAL INJURY OR PROPERTY DAMAGE, ARISING FROM THIS AGREEMENT AND ANY VIOLATION BY YOU OF ANY FEDERAL, STATE, OR LOCAL LAWS, STATUTES, RULES, OR REGULATIONS, EVEN IF COMPANY HAS BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. EXCEPT AS PROHIBITED BY LAW, IF THERE IS LIABILITY FOUND ON THE PART OF COMPANY, IT WILL BE LIMITED TO THE AMOUNT PAID FOR THE PRODUCTS AND/OR SERVICES, AND UNDER NO CIRCUMSTANCES WILL THERE BE CONSEQUENTIAL OR PUNITIVE DAMAGES. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF PUNITIVE, INCIDENTAL OR
CONSEQUENTIAL DAMAGES, SO THE PRIOR LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.</p>
      <h2 style={{paddingTop:"15px"}}>20. Termination</h2>
      <p>We may terminate or suspend your account and bar access to Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of Terms. If you wish to terminate your account, you may simply discontinue using Service.</p>
      <p>All provisions of Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</p>
      <h2 style={{paddingTop:"15px"}}>21. Governing Law</h2>
      <p>These Terms shall be governed and construed in accordance with the laws of USA, which governing law applies to agreement without regard to its conflict of law provisions.
Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Website and supersede and replace any prior agreements we might have had between us regarding Website.</p>
      <h2 style={{paddingTop:"15px"}}>22. Changes to Website</h2>
      <p>We reserve the right to withdraw or amend our Website, and any service or material we
provide via Service, in our sole discretion without notice. We will not be liable if for any reason all or any part of Website is unavailable at any time or for any period. From time to time, we may restrict access to some parts of Website, or the entire Website, to users, including registered users.</p>
      <h2 style={{paddingTop:"15px"}}>23. Amendments to Terms</h2>
      <p>We may amend Terms at any time by posting the amended terms on this site. It is your responsibility to review these Terms periodically.</p>
      <p>Your continued use of the Platform following the posting of revised Terms means that you accept and agree to the changes. You are expected to check this page frequently so you are aware of any changes, as they are binding on you.</p>
      <p>By continuing to access or use our Website after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use Website.</p>
      <h2 style={{paddingTop:"15px"}}>24. Waiver and Severability</h2>
      <p>No waiver by Company of any term or condition set forth in Terms shall be deemed a
further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of Company to assert a right or provision under Terms shall not constitute a waiver of such right or provision.</p>
      <p>If any provision of Terms is held by a court or other tribunal of competent jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of Terms will continue in full force and effect.</p>
      <h2 style={{paddingTop:"15px"}}>25. Acknowledgement</h2>
      <p>BY USING WEBSITE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS & CONDITIONS AND AGREE TO BE BOUND BY THEM.</p>
      <h2 style={{paddingTop:"15px"}}>26. Contact Us</h2>
      <p>Please send your feedback, comments, requests for technical support by email: <strong>support@cassy.dev</strong>.</p>
      </div>
    </div>
    <Footer />
  </>
);

export default (TermsOfService);
