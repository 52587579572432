import axios from 'axios'
import axiosRetry from 'axios-retry';
import * as vars from './vars';
import {sortMeetings, getTimezone} from '../../Utils/time'

    export function getWebinar (name){
      const params = new URLSearchParams({name:name})
      return axios({
          method: 'GET',
          url: vars.baseURL + '/api/v2/meetings/webinar/get?' + params,
          headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
          data: {}
      })
    }

    export function markUntrustedWebinarRegistration (webinarName, email, firstName, lastName, phone, attending){
      const params = new URLSearchParams({name: webinarName, attending: attending})
      return axios({
          method: 'POST',
          url: vars.baseURL + '/api/v2/untrusted/meetings/webinar/registration?' + params,
          headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
          data: {
            firstName: firstName,
            lastName: lastName,
            email: email,
            phone: phone
          }
      })
    }

    export function markTrustedWebinarRegistration (userId, webinarName, attending){
      const params = new URLSearchParams({userId: userId, name: webinarName, attending: attending})
      return axios({
          method: 'POST',
          url: vars.baseURL + '/api/v2/meetings/webinar/registration?' + params,
          headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
          data: {}
      })
    }

    export function requestMeeting (data) {
      const params = new URLSearchParams({timezone: getTimezone()})
        return axios({
            method: 'POST',
            url: vars.baseURL + '/api/v2/scheduling/meeting/propose?' + params,
            headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
            data: data
        })
      }

    export function confirmMeeting (data){
        const params = new URLSearchParams({meetingId:data.meetingId, timezone: getTimezone()})
        return axios({
            method: 'POST',
            url: vars.baseURL + '/api/v2/scheduling/meeting/confirm?' + params,
            headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
            data: data
        })
      }

    export function cancelMeeting (meetingId, userId, reason) {
        const params = new URLSearchParams({meetingId:meetingId, userId:userId, timezone: getTimezone()})
        return axios({
            method: 'POST',
            url: vars.baseURL + '/api/v2/scheduling/meeting/proposed/cancel?' + params,
            headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
            data: {
                reason: reason
            }
        })
      }

    export function reviewMeeting (data, meetingId, userId){
        const params = new URLSearchParams({meetingId:meetingId, userId:userId})
        return axios({
            method: 'POST',
            url: vars.baseURL + '/api/v2/meetings/meeting/review?' + params,
            headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
            data: data
        })
      }

    export function getMeetingReviews (meetingId){
        const params = new URLSearchParams({meetingId:meetingId})
        return axios({
            method: 'GET',
            url: vars.baseURL + '/api/v2/meetings/meeting/review/for_meeting?' + params,
            headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
            data: {}
        })
    }

    export function testJoinMeeting (userId, meetingId) {
        const params = new URLSearchParams({userId:userId, meetingID:meetingId, timezone: getTimezone()})
        return axios({
            method: 'GET',
            url: vars.baseURL + '/api/v2/meetings/join/test?' + params,
            headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
            data: {}
        })
      }

    export function joinMeeting (userId, meetingId, ip) {
        const params = new URLSearchParams({userId:userId, meetingID:meetingId, timezone: getTimezone()})
        return axios({
            method: 'POST',
            url: vars.baseURL + '/api/v2/meetings/join?' + params,
            headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
            data: {ip: ip}
        })
      }

    export function leaveMeeting (userId, meetingId) {
        const params = new URLSearchParams({userId:userId, meetingId:meetingId})
        return axios({
            method: 'POST',
            url: vars.baseURL + '/api/v2/meetings/meeting/leave?' + params,
            headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
            data: {}
        })
    }

    export function getAccessCode (email, username) {
        return axios({
            method: 'POST',
            url: vars.baseURL + '/api/v2/users/registration/verification' ,
            headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
            data: {
              email: email,
              userName: username
            }
        })
      }

    export function getMatches (data) {
        const params = new URLSearchParams(data)
        const client = axios.create({
          method: 'GET',
          baseURL: vars.baseURL,
          headers: { api_key: vars.apiKey },
          data: {}
        });
        axiosRetry(client, { retries: 3 });
        return client.get('/api/v2/algorithmic/matches/find?' + params)
      }

    export function checkMeetingAvailability (userId, date, timezone, duration) {
        const params = new URLSearchParams({userId: userId, date: date, timezone: timezone, duration:duration})
        return axios({
          method: 'GET',
          url: vars.baseURL + '/api/v2/scheduling/calendar/availability/when_can_start_meeting?' + params,
          headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
          data: {}
        })
      }

    export function logAvailabilitySearch (userId, duration, searcher, ipedsid) {
        const params = new URLSearchParams({userId: userId, duration:duration, searcher:searcher, ipedsid:ipedsid})
        return axios({
          method: 'POST',
          url: vars.baseURL + '/api/v2/scheduling/calendar/availability/log?' + params,
          headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
          data: {}
        })
      }

    export function getAllMeetings () {
        return axios({
            method: 'GET',
            url: vars.baseURL + '/api/v2/scheduling/meetings/all',
            headers: { api_key: vars.apiKey },
            data: {}
        })
    }

    export function getUserMeetings (id) {
      const params = new URLSearchParams({userId:id})
      return axios({
        method: 'GET',
        url: vars.baseURL + '/api/v2/scheduling/meeting/all?' + params,
        headers: { api_key: vars.apiKey },
        data: {}
      })
    }

    export const getSortedMeetings = (id) => {
        return  getUserMeetings(id).then(rsp => {
            if (rsp.status === 200 && rsp.data){
                let sorted = sortMeetings(rsp.data);
                return sorted
            }
        }).catch(error => {
            throw error.response
        })
    }

    export function getMeetingRecordings (meetingId) {
        const params = new URLSearchParams({meetingId:meetingId})
        return axios({
            method: 'GET',
            url: vars.baseURL + '/api/v2/scheduling/meetings/recordings?' + params,
            headers: { api_key: vars.apiKey },
            data: {}
        })
    }

    export function getMeetingById (meetingId) {
      const params = new URLSearchParams({meetingId:meetingId})
      return axios({
          method: 'GET',
          url: vars.baseURL + '/api/v2/scheduling/meetings/meeting?' + params,
          headers: { api_key: vars.apiKey },
          data: {}
      })
  }
