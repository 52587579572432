import React, { useState, useRef } from 'react'
import { Root } from '../../Api/Root'
import Button from 'react-bootstrap/Button'
import Dropdown from 'react-bootstrap/Dropdown'
import { BsFilter} from 'react-icons/bs'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import { Typeahead } from 'react-bootstrap-typeahead'
import { collegeIds } from '../../Utils/colleges'
import s from './styles/Search.module.scss'
import { TextInput } from '../Input/Input';
import { AiOutlineSearch } from 'react-icons/ai'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const Subscribe = ({className, containerClassName, height, placeholder}) => {
    const [email, setEmail] = useState(null)

      function subscribeHandler () {
        if (email && email.length > 0) {
          Root.miscApi.subscribeToNewsletter(email)
          .then(unused => {
            setEmail(null);
            toast("Thanks for subscribing!", {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            });
          }).catch(e => {
            console.log(e);
            toast.error("Unable to subscribe at this time.", {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            });
          });
        }
      }

    return <div className={`${containerClassName}`}>
    <ToastContainer />
          <InputGroup>
            <FormControl style={{height:height, maxWidth: '300px'}}  maxLength={"255"} placeholder={placeholder} className={`${className}`} value={email || ''} name={"text"} onChange={(e)=>{setEmail(e.target.value)}}/>
            <Button style={{height:height, borderTopRightRadius: '10px', borderBottomRightRadius: '10px'}} className={`btnFill ${s.subscribeButton}`} onClick={subscribeHandler}>
              <span style={{color: 'white', fontWeight: '600'}} className={`${s.subscribeButtonText}`}>Subscribe</span>
            </Button>
          </InputGroup>
        </div>
}
