import axios from 'axios'
import * as vars from './vars';

 export function getCollege (ipedsid) {
        const params = new URLSearchParams({ipedsid: ipedsid})
        return axios({
            method: 'GET',
            url: vars.baseURL + '/api/v2/college/search?' + params,
            headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
            data: {}
        })
    }

export function getCollegeReviews (ipedsid) {
        const params = new URLSearchParams({ipedsid:ipedsid})
        return axios({
            method: 'GET',
            url: vars.baseURL + '/api/v2/colleges/college/reviews?' + params,
            headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
            data: {}
        }) 
      }

export function reviewCollege (data, ipedsid, userId){
        const params = new URLSearchParams({ipedsid:ipedsid, userId:userId})
        return axios({
            method: 'POST',
            url: vars.baseURL + '/api/v2/colleges/college/review?' + params,
            headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
            data: data
        })
      }

export function getCollegeImages (ipedsid) {
        const params = new URLSearchParams({ipedsid: ipedsid})
        return axios({
            method: 'GET',
            url: vars.baseURL + '/api/v2/college/images/professional?' + params,
            headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
            data: {}
        })
      }

export function getCollegeHighlights (ipedsid, type) {
        let input = {ipedsid: ipedsid}
        if (type) {
            input['type'] = type
        }
        const params = new URLSearchParams(input)

        return axios({
            method: 'GET',
            url: vars.baseURL + '/api/v2/college/images/all/user_generated?' + params,
            headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
            data: {}
        })
      }

export function getAllUserHighlights () {
        return axios({
            method: 'GET',
            url: vars.baseURL + '/api/v2/college/images/user_generated/all',
            headers: { api_key: vars.apiKey },
            data: {}
        })
    }

export function approveUserHighlight (id) {
        const params = new URLSearchParams({id:id})
        return axios({
            method: 'POST',
            url: vars.baseURL + '/api/v2/college/images/user_generated/approve?' + params,
            headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
            data: {}
        })
    }

export function hideUserHighlight (id) {
        const params = new URLSearchParams({id:id})
        return axios({
            method: 'POST',
            url: vars.baseURL + '/api/v2/college/images/user_generated/hide?' + params,
            headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
            data: {}
        })
    }

    export function  removeUserHighlight (id) {
        const params = new URLSearchParams({id:id})
        return axios({
            method: 'DELETE',
            url: vars.baseURL + '/api/v2/college/images/user_generated/remove?' + params,
            headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
            data: {}
        })
    }

    export function  getRepresentedCollegesCount (){
        return axios({
            method: 'GET',
            url: vars.baseURL + '/api/v2/unprotected/colleges/all/size',
            headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
            data : {}
        })
    }

    export function getMentorCount (){
        return axios({
            method: 'GET',
            url: vars.baseURL + '/api/v2/unprotected/mentors/all/size',
            headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
            data : {}
        })
    }

    export function getCollegesWithMentors () {
        return axios({
          method: 'GET',
          url: vars.baseURL + '/api/v2/college/with_mentors/names',
          headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
          data: {}
        })  
      }

 export function  getFavorites (userId) {
        const params = new URLSearchParams({userId: userId})
        return axios({
            method: 'GET',
            url: vars.baseURL + '/api/v2/colleges/college/favorite?' + params,
            headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
            data: {}
        })
      }
    
  export function unfavorite (ipedsid, userId) {
        return axios({
            method: 'DELETE',
            url: vars.baseURL + '/api/v2/colleges/college/favorite' ,
            headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
            data : {
              ipedsid: ipedsid,
              userId: userId
            }
        })
      }
    
export function favorite (ipedsid, userId) {
        return axios({
            method: 'POST',
            url: vars.baseURL + '/api/v2/colleges/college/favorite' ,
            headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
            data : {
              ipedsid: ipedsid,
              userId: userId
            }
        })
      }
      
export function getCollegeFavorites (ipedsid) {
        return axios({
            method: 'GET',
            url: vars.baseURL + '/api/v2/college/favorites/school/all?ipedsid=' + ipedsid,
            headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
            data: {}
        })
    }

export function getDiscoveryImages (userId) {
    const params = new URLSearchParams({userId: userId})
    return axios({
        method: 'GET',
        url: vars.baseURL + '/api/v2/discovery/college_images?' + params,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: {}
    })
}

export function getDiscoveryVideos (userId) {
    const params = new URLSearchParams({userId: userId})
    return axios({
        method: 'GET',
        url: vars.baseURL + '/api/v2/discovery/college_videos?' + params,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: {}
    })
}

export function submitAmbassadorApp (userId, data) {
        const params = new URLSearchParams({userId: userId})
        return axios({
            method: 'POST',
            url: vars.baseURL + '/api/v2/ambassadors/ambassador/create?' + params,
            headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
            data : data
        })
      }
    
export function submitMeetMentorApp (userId, data) {
        const params = new URLSearchParams({userId: userId})
        return axios({
            method: 'POST',
            url: vars.baseURL + '/api/v2/campaigns/mentor/apply?' + params,
            headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
            data : data
        })
      }

//   GET /api/v2/discovery/colleges
//   Params: userId, query
export function searchColleges(query) {
    const params = new URLSearchParams({query: query, userId: 29})
    return axios({
        method: 'GET',
        url: vars.baseURL + '/api/v2/discovery/colleges?' + params,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: {}
    })
}