import axios from 'axios';
import * as vars from './vars';

  export function authenticateUser (credentials, userType) {
    var url;
    if (userType === 'student') {
      url = '/api/v2/users/authentication'
    } else if(userType === 'partner') {
      url = '/api/v2/counsel/counselor/login'
    } else if (userType === 'employer') {
      url = '/api/v2/employment/authentication/employees'
    }
    return axios({
      method: 'POST',
      url: vars.baseURL + url,
      headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
      data: credentials
    })
  }

  export function sendPasswordRecoveryCode (email) {
    return axios({
        method: 'POST',
        url: vars.baseURL + '/api/v2/users/credentials/recovery/start',
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data : {
          email: email
        }
    })
  }

  export function validatePasswordRecoveryCode (email, code) {
    return axios({
        method: 'POST',
        url: vars.baseURL + '/api/v2/users/credentials/recovery/continue',
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data : {
          email: email,
          code: code,
        }
    })
  }

  export function setNewPasswordRecovery (email, password){
    return axios({
      method: 'POST',
      url: vars.baseURL + '/api/v2/users/credentials/recovery/complete',
      headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
      data : {
        email: email,
        password: password,
      }
  })
  }

  export function getAccessCode (email, username) {
    return axios({
        method: 'POST',
        url: vars.baseURL + '/api/v2/users/registration/verification' ,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': this.csrfToken},
        data: {
          email: email,
          userName: username
        }
    })
  }
