import 'moment/locale/it'
import {Root} from '../Api/Root'
var moment = require('moment-timezone')

export function validateEmail (email) {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export function validateName (name) {
  return name && /^[a-zA-Z-]+$/.test(name.trim())
}

export function validateUserName (name) {
  if (!name) {
    return false
  }
  return /^[a-zA-Z0-9\-_]+$/.test(name) && name.length >= 5
}
export function validatePhone (phone) {
  if (!phone) {
    return false
  }
  if (!phone.startsWith(1)) {
    return false
  }
  return phone.replace(/\D/g, '').length === 11
}

export function validateSSN (ssn) {
  if (!ssn) {
    return false
  }
  return /^\d+$/.test(ssn) && ssn.length === 4
}

export function validateCode (code) {
  if (!code) {
    return false
  }
  return /^\d+$/.test(code)
}

export function validateStreet (street) {
  if (!street) {
    return false
  }
  return /^\s*\S+(?:\s+\S+){2}/.test(street)
}

export function validateZIP (zip) {
  if (!zip) {
    return false
  }
  return /^\d+$/.test(zip) && zip.length === 5
}

export function cleanPhone (phone) {
  return phone.replace(/\D/g, '')
}

export function validateDateObj (dateObj) {
  return validateDate(dateObj.toLocaleDateString())
}

export function validateDate (s) {
  if (!s) {
    return false
  }
  var bits = s.split('/')
  const date = moment(`${bits[0]}/${bits[1]}/${bits[2]}`).locale('en')
  return /^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$/.test(s) && date.isValid()
}

export function validateAge (s) {
  if (!s) {
    return false
  }
  if (!validateDate(s)) {
    return false
  }
  var bits = s.split('/')
  var years = moment().locale('en').diff(`${bits[0]}/${bits[1]}/${bits[2]}`, 'years')
  return years > 14
}

export function validatePassword (password) {
  if (!password) {
    return false
  }
  return password.length >= 8
}

export function push (obj, key, value) {
  if (!obj[key]) {
    obj[key] = []
  }
  obj[key].push(value)
}

// Returns formatted version of phone number with or without country code
export function formatPhoneNumber (phoneNumberString) {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    var intlCode = (match[1] ? '+1 ' : '')
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
  }
  return null
}

export function capitalizeFLetter (inputString) {
  var resString = ''
  const words = inputString.split(' ')
  words.forEach(word => {
    const resWord = word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    resString += resWord + ' '
  }
  )
  return resString.trim()
}

export function validateReferrer (code) {
  if (!validateEmail(code) && !validateUserName(code)) {
    return new Promise((resolve, reject) => {
      resolve(false)
    })
  }
  return Root.userApi.validateReferralCode(code).then(res => {
    if (res.status === 200) {
      return res.data
    }
  }).catch(() => {
    return true
  })
}
