import * as Auth from './Utils/auth';
import * as User from './Utils/user';
import * as Connection from './Utils/connection';
import * as Media from './Utils/media';
import * as Notifications from './Utils/notifications';

const merged = {
  ...Auth,
  ...User,
  ...Media,
  ...Connection,
  ...Notifications
}

export default merged;