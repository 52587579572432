import React, {useEffect} from 'react';
import { NavBar } from '../../Components/Nav/NavBar';
import s from './styles/Blog.module.scss';
import Footer from './Footer';
import { Helmet } from 'react-helmet';
import { Root } from '../../Api/Root';

const HermesOnboarding = () => {

  useEffect(() => {
    Root.miscApi.acknowledgeDownload().catch(error => {})
  }, []);

  return <>
  <Helmet>
      <title>Hermes Onboarding - Cassy</title>
    </Helmet>
    <div className={s.blog}>
    <NavBar style={{ backgroundColor:'#FFFFFF'}}/>
      <div className={s.sectionOne}>
        <div className={`${s.container}`}>
          <h1><strong>Getting started with Hermes</strong></h1>
          <p className={`text-center`}>You're all set. Your supercharged workflow starts now!</p>
        </div>
      </div>
      <div className={`${s.sectionTwo} ${s.container} `}>
        <h2>Helpful Tips</h2>
        <p>We made Hermes to be incredibly intuitive. No tutorial is neccessary;
          however, we'll still offer some times and tricks to make sure you get
          the most out of Hermes.</p>
          <ul>
            <li>Pin the Hermes Extension to the toolbar for easy access.</li>
            <li>Keep track of multiple conversations by creating up to 5 topics.</li>
            <li>Check the box labeled <i>Include & enable audio response</i> to generate an audio version of the response as well.</li>
            <li>The button labled <i>No evidence yet</i> will change colors and
              become <i>Try showing evidence</i> when Hermes has found a quote
              within the document to support its response. Click the button to
              jump to the section of text within the document.</li>
          </ul>
        </div>
    </div>
    <Footer />
  </>
};

export default (HermesOnboarding);
