import React from 'react';
import logo from '../../Static/Logo/darklogo.svg';
import {Subscribe} from '../../Components/Input/Subscribe';
import Button from 'react-bootstrap/Button';
import s from './styles/Footer.module.scss';
import iphone from './static/iphone_mockup.svg'
import {AiFillFacebook, AiFillInstagram, AiFillLinkedin } from 'react-icons/ai'

const Footer = () => (
  <>
    <div className={s.footer}>
        <div className={`${s.grid}`}>
          <div>
            <span>
              <img
                alt=""
                src={logo}
                height="50"
                className="d-inline-block align-center"
              />
            </span>
            <p className="mt-3">We're on a mission to innovate and invent new technology that furthers the material and social progression of mankind.</p>
          </div>
          <div className={`${s.siteMapContainer}`}>
            <Button style={{color:'white'}} variant="link" className='d-block text-start fs-13' href="/hermes">Hermes</Button>
            <Button style={{color:'white'}} variant="link" className='d-block text-start fs-13' href="/about">About</Button>
            <Button style={{color:'white'}} variant="link" className='d-block text-start fs-13' href='/contact'>Contact</Button>
            <Button style={{color:'white'}} variant="link" className='d-block text-start fs-13' href="/hermes/tos">Hermes Terms and Conditions</Button>
            <Button style={{color:'white'}} variant="link" className='d-block text-start fs-13' href="/hermes/privacy">Hermes Privacy Policy</Button>
          </div>
          <div className={`${s.subscribeContainer}`}>
            <p className={`${s.subscribe}`}>Subscribe for updates</p>
            <Subscribe color="orange" text="Subscribe" placeholder="Your email address" />
            <div className={`${s.socials}`}>
              <Button style={{color:'white'}} variant="link" target='_blank' href='https://www.linkedin.com/company/cassy-technology-company/'><AiFillLinkedin size='23'/></Button>
              <Button style={{color:'white'}} variant="link" target='_blank' href="https://www.instagram.com/hermes.cassy"><AiFillInstagram size='23'/></Button>
              <Button style={{color:'white'}} variant="link" target='_blank' href="https://www.facebook.com/profile.php?id=61558007714878"><AiFillFacebook size='23'/></Button>
            </div>
          </div>
        </div>
      </div>
  </>
);

export default (Footer);
